import React from "react";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
const savedLanguage = localStorage.getItem('selectedLanguage');
const defaultLanguage = savedLanguage ? JSON.parse(savedLanguage).value : 'EN';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      EN: {
        translation: {
             //dasboard
         dasboard_learn: "Learn How to use this System",
         DESC_USE:"Here you will find simple tutorials on how to use the CFMOTO WORLD software. A new user needs to read carefully and understand how the system works. We have created guides for the dealer, the service person and the vehicle owner.",
        Step_1:"Step 1",
        Step_1_desc:"Everyone who joins the system identifies themselves and the system identifies a specific person. Each person is personally responsible for the requests made with their user account and the data entered.",
        Step_2:"Step 2",
        Step_2_desc:"Each user group can perform queries, enter data and view the previous history of the vehicle according to the permitted rights.",
        Step_3:"Step 3",
        Step_3_desc:"Dear partners, all the information entered here is necessary for you and other partners. To simplify vehicle maintenance and to know how to perform the best maintenance for this particular vehicle.",
        //sidebar 
        Dashboard:"Dashboard",
        work:"WORKS",
        add:"Add",
        MY:"My",
        pending:"Pending",
        finished:"Finished",








        Vehicle:"Vehicle",
        Maintenance:"Maintenance",
        Repairing:"Repairing",
        Parts:"Parts",
        Accessories:"Accessories",
        Service_manual:"Service manual",
        User_manual:"User manual",
        Warranty:"WARRANTY",
        Warrantynew:"Warranty",
        Bulletins:"Bulletins",
        Log_out:"Log out",
        //vehicle data
        Warranty_Registration:"Warranty Registration",
        History:"HISTORY ",
        SKU:"SKU",
        VIN_CODE:"VIN CODE",
        COUNTRY:"COUNTRY",
        SERIES:"SERIES",
        MODEL_NAME:"MODEL NAME",
        FACTORY_NAME:"FACTORY NAME",
        COLOR:"COLOR",
        EU_TYPE_APPROVAL:"EU TYPE APPROVAL",
        STEERING_POWER:"STEERING POWER",
        WHEELS:"WHEELS",
        SCREEN:"SCREEN",
        LIGHTS:"LIGHTS",
        CARGO_COMPARTMENTS:"CARGO COMPARTMENTS",
        COMMUNICATION_TERMINAL:"COMMUNICATION TERMINAL",
        OWNER_NAME:"OWNER NAME",
        OWNER_PHONE:"OWNER PHONE",
        OWNER_EMAIL:"OWNER EMAIL",
        VEHICLE_NUMBER_PLATE:"VEHICLE NUMBER PLATE",
        IMPORTER:"IMPORTER",
        DEALER:"DEALER",
        //maintainence
        Select_the_mileage:"Select the mileage",
        next:"Next",
        Mileage_at_maintenance:"Mileage at maintenance",
        Maintenance_description:"Maintenance description",
        Spare_parts_for_service:"Spare parts for service:",
        Vehicle_photos:"Vehicle photos:",
        Vehicle_photos_desc:"Photo of the speedometer (numbers must be legible), VIN code (numbers must be legible), 45° front view ja 45° rear view",
        Maintainence_list_desc:"Maintenance service checklist",
        Maintainence_list_desc_2:"Please mark when you finish each section",
        Mechanic_comment :"Mechanic comment :",
        Maintainence_button_text:"Save maintenance  Data",
        //waranty
        waranty_milage:"Mileage at service ",
        Failure_description:"Failure description",
        Upload_photos_and_videos:"Upload photos and videos",
        Replaced_parts:"Replaced parts",
        Cause:"Cause",
        repair:"Repair",
        Remarks:"Remarks",
        Review:"Review",
        Save_warranty_data:"Save warranty data",
        //repairing
        Mileage_of_Repairing:"Mileage of Repairing",
        Customer_description_of_errors:"Vehicle Owner Description",
        The_receiver_oftheworks_description:"Repairing Description",
        Feedback_from_the_mechanic:"Feedback from the mechanic",
        Save_repairing_Data:"Save repairing Data",
        //bulletin
        Bulletins_description:"Bulletins description:",
        
        Factory_instructions:"Factory instructions",
        
        OPEN_FILE:"OPEN FILE",
        bulettin_Mechanic_comment:"Mechanic comment :",
        Bulletin_executed:"Bulletin executed",



        //waranty registration
        Company:"COMPANY",
        registration_desc:"The vehicle has been handed over to the owner. All the necessary briefing has been given to the owner.",
        // vinn number page
        EnterVINnumber:"Enter VIN/Plate number",
        vinnumber:"VIN number",
        platenumber:"Plate number",
        SUBMIT:"SUBMIT",
        OR: "or",
        Addspeedometer:"Add speedometer",
        addvincode:"Add  VIN code",
        add45front:"Add 45° front view",
        add45back:"Add 45° rear view",
        addleft:"Add left view",
        addright:"Add right view",
        




        










        }
      },
      ET: {
        translation: {
             //dasboard
            dasboard_learn: "Õpi süsteemi kasutama",
            DESC_USE:"Siit leiad CFMOTO WORLD programmi kasutusjuhendid. Uus kasutaja peaks juhendid hoolikalt läbi lugema ning endale süsteemi selgeks tegema. Kasutusjuhendid oleme loonud edasimüüjale, hooldajale ning sõiduki omanikule.",
            Step_1:"Samm 1",
            Step_1_desc:"Süsteemiga ühinedes, kasutaja tuvastab enda isiku. Iga isik on vastutav enda kasutaja alt sisestatud info ja andmete korrektsuses.",
            Step_2:"Samm 2",
            Step_2_desc:"Iga kasutajagrupp saab oma kasutaja õigustele vastavalt teha päringuid, sisestada andmeid ning näha ajalugu.",
            Step_3:"Samm 3",
            Step_3_desc:"Austatud partnerid, kogu informatsioon on teile ning teistele partneritele vajalik hoolduse süsteemi lihtsustamiseks ning parima hoolduse teostamiseks kindlale sõidukile.", 
            //sidebar 
            Dashboard:"Juhtpaneel",
            work:"töötab",
            add:"Lisa",
            MY:"Minu",
            pending:"Ootel",
            finished:"Lõpetatud",
            Vehicle:"Sõiduk",
            Maintenance:"Hooldus",
            Repairing:"Remont",
            Parts:"Varuosad",
            Accessories:"Lisavarustus",
            Service_manual:"Hoolduse juhend",
            User_manual:"Kasutusjuhend",
            Warranty:"Garantii",
            Warrantynew:"Garantii",
            Bulletins:"Teated",
            Log_out:"Logi välja",
            //vehicle data
            Warranty_Registration:"Garantii registreerimine",
            History:"Ajalugu",
            SKU:"SKU",
            VIN_CODE:"VIN KOOD",
            COUNTRY:"RIIK",
            SERIES:"SEERIA",
            MODEL_NAME:"MUDEL",
            FACTORY_NAME:"TEHASETÄHIS",
            COLOR:"VÄRVUS",
            EU_TYPE_APPROVAL:"EU TÜÜBIKINNITUS",
            STEERING_POWER:"ROOLIVÕIMENDI",
            WHEELS:"REHVID",
            SCREEN:"EKRAAN",
            LIGHTS:"TULED",
            CARGO_COMPARTMENTS:"PANIPAIGAD",
            COMMUNICATION_TERMINAL:"T-BOX",
            OWNER_NAME:"OMANIKU NIMI",
            OWNER_PHONE:"OMANIKU TELEFON",
            OWNER_EMAIL:"OMANIKU E-MAIL",
            IMPORTER:"MAALETOOJA",
            DEALER:"MÜÜJA",
            //maintainence
            Select_the_mileage:"Valige läbisõit",
            next:"järgmiseks",
            Mileage_at_maintenance:"Hoolduse läbisõit",
            Maintenance_description:"Hoolduse kirjeldus",
            Spare_parts_for_service:"Hoolduse varuosad:",
            Vehicle_photos:"Sõiduki pildid:",
            Vehicle_photos_desc:"Pilt spidomeetrist ( peab olema loetav ), VIN kood ( peab olema loetav ), 45° eestvaade ja 45° tagantvaade",
            Maintainence_list_desc:"Hooldusteenuste kontrollnimekiri",
            Maintainence_list_desc_2:"Palun märgi peale iga sammu lõpetamist",
            Mechanic_comment :"Mehaaniku kommentaar:",
            Maintainence_button_text:"Salvesta andmed",
            //waranty
            waranty_milage:"Sõiduki läbisõit",
            Failure_description:"Vea kirjeldus",
            Replaced_parts:"Vahetatud osad",
            Upload_photos_and_videos:"Piltide ja videote üles laadimine",
            Cause:"Põhjus",
            repair:"Remont",
            Remarks:"Märkused",
            Review:"Ülevaade",
            Save_warranty_data:"Salvesta andmed",
             //repairing
        Mileage_of_Repairing:"Remondi läbisõit",
        Customer_description_of_errors:"Sõiduki omaniku kirjeldus",
        The_receiver_oftheworks_description:"Remondi kirjeldus",
        Feedback_from_the_mechanic:"Mehaaniku tagasiside",
        Save_repairing_Data:"Salvesta andmed",
        //bulletin
        Bulletins_description:"Teadete kirjeldus:",
        Factory_instructions:"Tehase juhised",
        OPEN_FILE:"AVA FAIL",
        bulettin_Mechanic_comment:"Mehaaniku kommentaar:",
        Bulletin_executed:"Bulletin executed",
         //waranty registration
         Company:"Ettevõte",
         registration_desc:"Sõiduk on omanikule üle antud. Omanikule on antud kogu vajalik instruktaaž.",
         // vinn number page
         EnterVINnumber:"Sisestage VIN-kood/numbrimärk",
         vinnumber:"VIN number",
         platenumber:"Numbrimärk",
         SUBMIT:"ESITA",
         OR: "või",
         Addspeedometer:"Lisa spidomeeter",
         addvincode:"Lisa VIN-kood",
         add45front:"Lisage 45° eestvaade",
         add45back:"Lisage 45° tagantvaade",
         addleft:"Lisa vasak vaade",
         addright:"Lisa parem vaade",
         
             
       
       
       
       
        }
      },
      FN: {
        translation: {
             //dasboard
            dasboard_learn: "Opi käyttämään järjestelmää",
            DESC_USE:"Tästä löydät CFMOTO WORLD -ohjelmiston käyttöohjeet. Uuden käyttäjän tulee lukea ohjeet huolellisesti ymmärtääkseen, kuinka järjestelmä toimii. Olemme laatineet ohjeet kauppaille, huoltohenkilöstölle sekä ajoneuvon omistajalle.",
            Step_1:"Vaihe 1",
            Step_1_desc:"Kaikkien järjestelmään liittyvien tulee tunnistautua, järjestelmä tunnistaa kunkin henkilön. Kukin henkilö on itse vastuussa käyttäjätilillään tehdyistä pyynnöistä ja syötetyistä tiedoista.",
            Step_2:"Vaihe 2",
            Step_2_desc:"Kukin käyttäjäryhmä voi tehdä kyselyjä, syöttää tietoja sekä tarkastella ajoneuvon historiaa käyttöoikeuksien mukaisesti.",
            Step_3:"Vaihe 3",
            Step_3_desc:"Arvoisat yhteistyökumppanit, tähän annetut tiedot ovat tärkeitä sekä sinulle että toisille yhteistyökumppaneille. Ajoneuvon huollon helpottamiseksi ja juuri tälle ajoneuvolle parhaiden mahdollisten huoltotoimien tietämiseksi.", 
            
            
            
            //sidebar 
            Dashboard:"Ohjauspaneeli",
            work:"toimii",
            add:"Lisää",
        MY:"Minun",
        pending:"Odottaa",
        finished:"Valmis",
            Vehicle:"Ajoneuvo",
            Maintenance:"Huolto",
            Repairing:"Korjaus",
            Parts:"Osat",
            Accessories:"Lisävarusteet",
            Service_manual:"Huolto-ohjekirja",
            User_manual:"Omistajan käsikirja",
            Warranty:"Takuu",
            Warrantynew:"Takuu",
            Bulletins:"Tiedotteet",
            Log_out:"Kirjaudu ulos",
            //vehicle data
            Warranty_Registration:"Takuun rekisteröinti",
            History:"Historia",
            SKU:"SKU",
            VIN_CODE:"VIN-KOODI",
            COUNTRY:"MAA",
            SERIES:"SARJA",
            MODEL_NAME:"MALLI",
            FACTORY_NAME:"TEHDASNIMITYS",
            COLOR:"VÄRI",
            EU_TYPE_APPROVAL:"EU-TYYPPIHYVÄKSYNTÄ",
            STEERING_POWER:"OHJAUSVOIMA",
            WHEELS:"PYÖRÄT",
            SCREEN:"NÄYTTÖ",
            LIGHTS:"VALOT",
            CARGO_COMPARTMENTS:"TAVARATILAT",
            COMMUNICATION_TERMINAL:"VIESTINTÄPÄÄTE",
            OWNER_NAME:"OMISTAJAN NIMI",
            OWNER_PHONE:"OMISTAJAN PUHELINNUMERO",
            OWNER_EMAIL:"OMISTAJAN SÄHKÖPOSTI",
            IMPORTER:"MAAHANTUOJA",
            DEALER:"KAUPPIAS",
            //maintainence
            Select_the_mileage:"Valitse mittarilukema",
            next:"Seuraava",
            Mileage_at_maintenance:"Mittarilukema huollossa",
            Maintenance_description:"Huollon kuvaus",
            Spare_parts_for_service:"Huollossa käytetyt varaosat:",
            Vehicle_photos:"Ajoneuvon kuvat:",
            Vehicle_photos_desc:"Kuva nopeusmittarista (numeroiden tulee näkyä), VIN-koodista (numeroiden tulee näkyä), edestä 45° kulmassa ja takaa 45° kulmasta",
            Maintainence_list_desc:"Huoltopalvelun tarkistuslista",
            Maintainence_list_desc_2:"Merkitse kukin kohde suorittamisen jälkeen",
            Mechanic_comment :"Mekaanikon kommentti:",
            Maintainence_button_text:"Saglabājiet apkopes datus",
            //waranty
            waranty_milage:"Mittarilukema huollossa",
            Failure_description:"Vian kuvaus",
            Replaced_parts:"Vaihdetut osat",
            Upload_photos_and_videos:"Lataa kuvia ja videoita",
            Cause:"Syy",
            repair:"Korjaus",
            Remarks:"Huomautukset",
            Review:"Arvio",
            Save_warranty_data:"Tallenna takuutiedot",
             //repairing
        Mileage_of_Repairing:"Korjauksen kilometrimäärä",
        Customer_description_of_errors:"Ajoneuvon omistajan kuvaus",
        The_receiver_oftheworks_description:"Kuvaus korjauksesta",
        Feedback_from_the_mechanic:"Mekaanikon kommentti",
        Save_repairing_Data:"Tallenna korjaustiedot",
        //bulletin
        Bulletins_description:"Ilmoituksen kuvaus:",
        Factory_instructions:"Tehtaan ohjeet",
        OPEN_FILE:"AVAA TIEDOSTO",
        bulettin_Mechanic_comment:"Mekaanikon kommentti:",
        Bulletin_executed:"Ilmoituksen vaatimat toimet suoritettu",
         //waranty registration
         Company:"Yhtiö",
         registration_desc:"Auto on luovutettu omistajalle. Kaikki tarvittavat tiedotukset on annettu omistajalle.",
          // vinn number page
          EnterVINnumber:"Syötä VIN-numero/Kilven-numero",
          vinnumber:"VIN-numero",
          platenumber:"Kilven-numero",
          SUBMIT:"LÄHETÄ",
          OR: "tai",
          Addspeedometer:"Lisää nopeusmittari",
          addvincode:"Lisää VIN-koodi",
          add45front:"Lisää 45° etunäkymä",
          add45back:"Lisää 45° näkymä takaa",
          addleft:"Lisää vasen näkymä",
          addright:"Lisää oikea näkymä",
         
         

        
        
        }
      },
      LV: {
        translation: {
            //dasboard
            dasboard_learn: "Uzziniet, kā izmantot šo sistēmu",
            DESC_USE:"Šeit jūs atradīsiet vienkāršas pamācības par CFMOTO WORLD programmatūras lietošanu. Jaunam lietotājam ir rūpīgi jāizlasa un jāsaprot, kā sistēma darbojas. Mēs esam izveidojuši ceļvežus izplatītājam, apkalpojošajam personālam un transportlīdzekļa īpašniekam.",
            Step_1:"1. darbība",
            Step_1_desc:"Katrs, kas pievienojas sistēmai, identificē sevi un sistēma identificē konkrētu personu. Katra persona ir personīgi atbildīga par pieprasījumiem, kas veikti, izmantojot savu lietotāja kontu, un ievadītajiem datiem.",
            Step_2:"2. darbība",
            Step_2_desc:"Katra lietotāju grupa atbilstoši atļautajām tiesībām var veikt vaicājumus, ievadīt datus un apskatīt transportlīdzekļa iepriekšējo vēsturi.",
            Step_3:"3. darbība",
            Step_3_desc:"Cienījamie partneri, visa šeit ievadītā informācija ir nepieciešama jums un citiem partneriem. Lai vienkāršotu transportlīdzekļa apkopi un zinātu, kā vislabāk veikt apkopi šim konkrētajam transportlīdzeklim.", 
            //sidebar 
            Dashboard:"Mērinstrumentu panelis",
            work:"darbojas",
            add:"Pievienot",
        MY:"Mana",
        pending:"Gaida",
        finished:"Pabeigts",
            Vehicle:"Transportlīdzeklis",
            Maintenance:"Apkope",
            Repairing:"Remonts",
            Parts:"Detaļas",
            Accessories:"Piederumi",
            Service_manual:"Servisa rokasgrāmata",
            User_manual:"Lietotāja rokasgrāmata",
            Warranty:"Garantija",
            Warrantynew:"Garantija",
            Bulletins:"Biļetens",
            Log_out:"Izlogoties",
            //vehicle data
            Warranty_Registration:"Garantijas reģistrācija",
            History:"Vēsture",
            SKU:"SKU",
            VIN_CODE:"VIN KODS",
            COUNTRY:"VALSTS",
            SERIES:"SĒRIJA",
            MODEL_NAME:"MODEĻA NOSAUKUMS",
            FACTORY_NAME:"RŪPNĪCAS NOSAUKUMS",
            COLOR:"KRĀSA",
            EU_TYPE_APPROVAL:"EU TIPA APSTIPRINĀJUMS",
            STEERING_POWER:"STURES Pastiprinātājs",
            WHEELS:"RITEŅI",
            SCREEN:"EKRĀNS",
            LIGHTS:"GAISMAS",
            CARGO_COMPARTMENTS:"Bagāžas nodalijums",
            COMMUNICATION_TERMINAL:"KOMUNIKĀCIJAS TERMINĀLS",
            OWNER_NAME:"ĪPAŠNIEKA VĀRDS",
            OWNER_PHONE:"ĪPAŠNIEKA TĀLRUNIS",
            OWNER_EMAIL:"ĪPAŠNIEKA E-PASTs",
            IMPORTER:"IMPORTĒTĀJS",
            DEALER:"Izplatītājs",
            //maintainence
            Select_the_mileage:"Izvēlieties nobraukumu",
            next:"Nākamais",
            Mileage_at_maintenance:"Nobraukums apkopes laikā",
            Maintenance_description:"Tehniskās apkopes apraksts",
            Spare_parts_for_service:"Rezerves daļas servisam:",
            Vehicle_photos:"Transportlīdzekļa fotogrāfijas:",
            Vehicle_photos_desc:"Spidometra fotoattēls (skaitļiem jābūt salasāmiem), VIN kodam (skaitļiem jābūt salasāmiem), 45° skats no priekšpuses un 45° skats no aizmugures",
            Maintainence_list_desc:"Hooldusteenuste kontrollnimekiri",
            Maintainence_list_desc_2:"Lūdzu, atzīmējiet, kad esat pabeidzis katru sadaļu",
            Mechanic_comment :"Mehāniķa komentārs:",
            Maintainence_button_text:"Saglabājiet apkopes datus",
            //waranty
            waranty_milage:"Nobraukums servisā",
            Failure_description:"Kļūdu apraksts",
            Replaced_parts:"Nomainītas detaļas",
            Upload_photos_and_videos:"Augšupielādējiet fotoattēlus un videoklipus",
            Cause:"Cēlonis",
            repair:"Remonts",
            Remarks:"Piezīmes",
            Review:"Pārskats",
            Save_warranty_data:"Saglabājiet garantijas datus",
             //repairing
        Mileage_of_Repairing:"Remonta nobraukums",
        Customer_description_of_errors:"Transportlīdzekļa īpašnieka apraksts",
        The_receiver_oftheworks_description:"Remonta apraksts",
        Feedback_from_the_mechanic:"Atsauksmes no mehāniķa",
        Save_repairing_Data:"Saglabājiet remonta datus",
        //bulletin
        Bulletins_description:"Biļetena apraksts:",
        Factory_instructions:"Rūpnīcas instrukcijas",
        OPEN_FILE:"ATVERT FAILU",
        bulettin_Mechanic_comment:"Mehāniķa komentārs:",
        Bulletin_executed:"Biļetens izpildīts",
         //waranty registration
         Company:"Uzņēmums",
         registration_desc:"Automašīna ir nodota īpašniekam. Īpašniekam ir sniegta visa nepieciešamā instruktāža.",
         // vinn number page
         EnterVINnumber:"Ievadiet VIN/Plāksnes numuru", 
         vinnumber:"VIN numurs",
         platenumber:"Plāksnes numurs",
         SUBMIT:"IESNIEGT",
         OR: "vai",
         Addspeedometer:"Pievienojiet spidometru",
         addvincode:"Pievienojiet VIN kodu",
         add45front:"Pievienojiet 45° priekšējo skatu",
         add45back:"Pievienojiet 45° aizmugures skatu",
         addleft:"Pievienot kreiso skatu",
         addright:"Pievienojiet labo skatu",
            
        }
      }
    },
    lng: defaultLanguage  , // if you're using a language detector, do not define the lng option
    fallbackLng: "EN",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


// append app to dom
export default i18n;