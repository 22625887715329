import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import Sidebar from "../components/Common/Sidebar";
import Sparepartmodal from "../components/spareparts/Sparepartmodal";
import Header from "../components/Common/Header";
import Modal from "react-modal";
import bike_img from "../images/bike_img.png";
import placeholder_img from "../images/camera_img.svg";
import SurveyForm from "../components/Service/Surveyform";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loaderActions } from "../state/actions";
import MultiImageUpload from "../components/Common/multiImageUpload";
import MultiVideoUpload from "../components/Common/multiVideoUpload";
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     // console.log("data=--_>> ",localStorage.getItem("vinnData"))
//     if(!localStorage.getItem("vinnData")){
//       navigate("/vinn")
//     }
//     else{
//       getApiData();
//     }

//   }, [])
const data = localStorage.getItem("vinnData");

const parsed_data = JSON.parse(data);
function Maintainence() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const data = localStorage.getItem("vinnData");
  const [showModal, setShowModal] = useState(false);
  const profile_id = localStorage.getItem("profile_id");
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const product_id = db_data?.id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  // console.log("db_data+++++>> ",db_data)
  const image_url = `${db_data?.image}`;
  // const [openSidebar, setOpenSidebar] = useState(false)

  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);
  const [loading, setLoading] = useState(true);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [latestWorkData, setLatestWorkData] = useState(null);

  const [spareParts, setSpareParts] = useState([]);
  const [files, setFiles] = useState([null, null, null, null]);
  const [elapsedTime, setElapsedTime] = useState(0);

  const timers = useRef([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  // const currentItem = services[currentIndex]
  ///////

  const [temptime, setTemp] = useState({});
  const [available, setAvailable] = useState(false);

  const timerRefm = useRef(0);
  const timerRef = useRef([]);
  const [timer, setTimer] = useState({});
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [timerValue, setTimerValue] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const filesInputRef = useRef(null);
  const videosInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  // const [maintenanceId, setMaintenanceId] = useState(3);
  const [productId, setProductId] = useState(3);
  const [mileage, setMileage] = useState();
  const [customerDescription, setCustomerDescription] = useState("");
  const [receiverDescription, setReceiverDescription] = useState("");
  const [feedback, setFeedback] = useState("");
  const [replaceParts, setReplaceParts] = useState([1]);
  const [prevtime, setprevtime] = useState("");
  const profile = localStorage.getItem("profile");
  const company_name = localStorage.getItem("company_name");
  const [dataPost, setDataPost] = useState({
    maintanence_id: 3,
    product_id: 3,
    mileage: 0,
    customer_description: "",
    receiver_description: "",
    feedback: "",
    replace_parts: [1],
  });
  const [tempdataPost, settempDataPost] = useState({
    // maintanence_id: 3,
    product_id: 3,
    mileage: 0,
    customer_description: "",
    receiver_description: "",
    feedback: "",
    replace_parts: [1],
  });
  // const handleTemporaryRepairButtonClick = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/temporaryrepairing/${db_data.id}/`);

  //     if (response.data /* Check if data is as expected */) {
  //       const responseData = response.data;

  //       setDataPost({
  //         ...dataPost, // Keep existing fields
  //         product_id: responseData.product_id || 3, // Update product_id if available
  //         mileage: responseData.mileage || 0,
  //         customer_description: responseData.customer_description || "",
  //         receiver_description: responseData.receiver_description || "",
  //         feedback: responseData.feedback || "",
  //         // You can update more fields as needed
  //       });

  //       setprevtime(responseData.time || "");
  //       setResponseData(responseData);
  //       setSelectedFile5(`${process.env.REACT_APP_API_URL}${responseData.picture || ""}`);
  //       setTemp(responseData.time);

  //       // Navigate to '/service/temporary-repair'
  //       // navigate('/service/temporary-repair');
  //     } else {
  //       // Show alert if no data
  //       toast.error('No data found', {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     alert('Error fetching data');
  //   }
  // };

  async function urlToFile(url, filename, mimeType) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const file = new File([blob], filename, { type: mimeType });
    return file;
  }

  const handleTemporaryRepairButtonClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/temporaryrepairing/${db_data.id}/`
      );

      if (response.data /* Check if data is as expected */) {
        const responseData = response.data;
        console.error("Error in converting URL to File: ", response.data);

        let pictureFile = "";
        if (responseData.picture) {
          try {
            const pictureUrl = `${process.env.REACT_APP_API_URL}${responseData.picture}`;
            pictureFile = await urlToFile(
              pictureUrl,
              "picture.png",
              "image/png"
            );
          } catch (error) {
            console.error("Error in converting URL to File: ", error);
          }
        }

        setDataPost({
          ...dataPost, // Keep existing fields
          product_id: responseData.product_id || 3,
          mileage: responseData.mileage || 0,
          customer_description: responseData.customer_description || "",
          receiver_description: responseData.receiver_description || "",
          feedback: responseData.feedback || "",
          picture: pictureFile || "",
          video: responseData.video || "",
        });

        setprevtime(responseData.time || "");
        setResponseData(responseData);
        setSelectedFile5(
          `${process.env.REACT_APP_API_URL}${responseData.picture || ""}`
        );
        setSelectedFile6(
          `${process.env.REACT_APP_API_URL}${responseData.video || ""}`
        );
        setTemp(responseData.time);

        // Navigate to '/service/temporary-repair'
        // navigate('/service/temporary-repair');
      } else {
        // Show alert if no data
        toast.error("No data found", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // alert('Error fetching data');
    }
  };

  const savetempMentainence = () => {
    postImgs();
    // console.log("lllllllllllkkkkkkkkkkkkkkkkkkk",selectedCards)
    const data = new FormData();
    // data.append("maintanence_id", dataPost?.maintanence_id)
    data.append("product_id", db_data?.id);
    data.append("mileage", dataPost?.mileage);
    data.append("customer_description", dataPost?.customer_description);
    data.append("receiver_description", dataPost?.receiver_description);
    data.append("feedback", dataPost?.feedback);
    data.append("replace_parts", selectedCards);
    images.forEach((image) => data.append("picture", image));
    videos.forEach((video) => data.append("video", video));
    data.append("time", timerRefm.current);

    console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz", dataPost);
    console.log(data.getAll("replace_parts"));
    navigate("/vehicleData");
    const res = axios.post(
      //  `${process.env.REACT_APP_API_URL}/api/temporary-repairing/{db_data?.id}/`,
      `${process.env.REACT_APP_API_URL}/api/temprepairing/create/`,
      // `${process.env.REACT_APP_API_URL}/api/repairing/create/`,
      data
    );
  };

  const [startedAt, setStartedAt] = useState(new Date().toISOString());

  const postRepairing = async () => {
    const data = new FormData();
    data.append("add_work", latestWorkData?.work_id || null);
    data.append("status", "ready");
    data.append("mileage_at_repairing_opening", dataPost?.mileage);
    data.append("vin_code", db_data?.vin_code);
    data.append("mechanic_id", profile_id);
    data.append("started_at", startedAt);
    data.append("vin_picture", files[1]);
    data.append("speedometer_picture", files[0]);
    data.append("front_45_view_picture", files[2]);
    data.append("rear_45_view_picture", files[3]);
    data.append("customer_description", dataPost?.customer_description);
    data.append("repairing_description", dataPost?.receiver_description);
    data.append("error_picture", "");
    data.append("spare_parts_list_id", 0);
    data.append("maintenance_checklist_id", 0);
    data.append("mechanic_comment", dataPost?.feedback || "");
    data.append("repairing_pictures_table_id", 0);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addwork/repairing/`,
        data
      );
      console.log("Maintenance created:", response.data);
    } catch (error) {
      console.error("Error creating maintenance:", error);
    }
  };
  const saveMentainence = () => {
    if (dataPost?.feedback == "") {
      toast.error("Please provide feedback");
      return;
    }
    if (dataPost?.customer_description == "") {
      toast.error("Please Customer description");
      return;
    }
    if (dataPost?.receiver_description == "") {
      toast.error("Please provide Receiver description");
      return;
    }
    if (dataPost?.mileage == "") {
      toast.error("Please provide Mileage");
      return;
    }
    // if (selectedCards.length == 0) {
    //   toast.error("Please select Spare Parts");
    //   return;
    // }
    // if (!selectedFile5) {
    //   toast.error("Please select picture");
    //   return;
    // }
    // if (!dataPost?.video) {
    //   toast.error("Please select video");
    //   return;
    // }
    dispatch(loaderActions.showLoader());
    postImgs();
    console.log("lllllllllllkkkkkkkkkkkkkkkkkkk", selectedCards);
    const data = new FormData();
    // data.append("maintanence_id", dataPost?.maintanence_id)
    data.append("product_id", db_data?.id);
    data.append("mileage", dataPost?.mileage);
    data.append("customer_description", dataPost?.customer_description);
    data.append("receiver_description", dataPost?.receiver_description);
    data.append("feedback", dataPost?.feedback);
    data.append("replace_parts", selectedCards);
    images.forEach((image) => data.append("picture", image));
    videos.forEach((video) => data.append("video", video));
    data.append("work_id", latestWorkData?.work_id || null);

    if (prevtime === "") {
      data.append("time", timerRefm.current);
    } else {
      data.append("time", timerRefm.current + prevtime);
    }

    console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz", data);
    console.log(data.getAll("replace_parts"));
    const res = axios
      .post(
        // `${process.env.REACT_APP_API_URL}/product-service/create/`,
        `${process.env.REACT_APP_API_URL}/api/repairing/create/`,
        // `${process.env.REACT_APP_API_URL}/api/repairing/create/`,
        data
      )
      .then((res) => {
        toast.success("Repairing created successfully.");
        navigate("/vehicleData");
        var formdata = new FormData();
        formdata.append(
          "description",
          `Repairing made by ${profile} from ${company_name}`
          // in ${
          //   timerRefm.current + prevtime
          // } seconds
        );
        formdata.append(
          "estonian_description",
          `Remondi tegi ${profile} ettevõttest ${company_name}`
        );
        formdata.append(
          "latvian_description",
          `Remontu veica ${profile} no ${company_name}`
        );
        formdata.append(
          "finnish_description",
          `Korjauksen teki ${profile}, ${company_name}`
        );

        formdata.append("historical_note", ``);
        formdata.append("vehicle", db_data?.id);
        formdata.append("owner_email", db_data?.owner_email);
        formdata.append("plate_number", db_data?.plate_number);
        formdata.append("vin_code", db_data?.vin_code);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(
          `${process.env.REACT_APP_API_URL}/history/create/`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            // Redirect to the desired location
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/delete_temporary_repairing/${responseData.id}/`
              )
              .then((tempRes) => {
                // Handle the response of the third POST call as needed
                console.log("Temporary Repairing Response:", tempRes);
              })
              .catch((tempError) => {
                console.error("Error in Temporary Repairing POST:", tempError);
                toast.error("Error in Temporary Repairing POST");
              });
          })
          .catch((error) => {
            console.error("Error while delete temporary repairing:", error);
          });
        postRepairing();
      })
      .catch((error) => {
        console.error("Error while creating repairing:", error);
        toast.error("Error while creating repairing.");
      })
      .finally(() => {
        dispatch(loaderActions.hideLoader());
      });
  };
  // const onChangeValue = (event, key) => {
  //   const value = event.target.value;

  //   // Use a switch statement to update the appropriate state based on the field
  //   switch (key) {

  //     case 'mileage':
  //       setMileage(value);
  //       break;
  //     case 'customerDescription':
  //       setCustomerDescription(value);
  //       break;
  //     case 'receiverDescription':
  //       setReceiverDescription(value);
  //       break;
  //     case 'feedback':
  //       setFeedback(value);
  //       break;
  //     default:
  //       break;
  //   }
  // };
  const onChangeValue = (event, key) => {
    setDataPost({
      ...dataPost,
      [key]: event.target.value,
    });
  };

  const getSpareParts = () => {
    const res = axios
      .get(
        // `${process.env.REACT_APP_API_URL}/product-service/create/`,
        `${process.env.REACT_APP_API_URL}/api/newspareparts/${db_data?.sku}/`

        // `${process.env.REACT_APP_API_URL}/api/newspareparts/${db_data?.sku}/`,
      )
      .then((res) => {
        if (res?.data && res?.data.length > 0) {
          setSpareParts(res?.data);
        }
      });
  };
  // const getProductImages = ()=>{
  //   const res =  axios.get(
  //     // `${process.env.REACT_APP_API_URL}/product-service/create/`,
  //     `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`,
  //   ).then((res)=>{
  //     if (res?.data && res.data.length > 0) {
  //       const data = res.data[0];

  //       if (data.image1) {
  //         setSelectedFile1(`${process.env.REACT_APP_API_URL}/${data.image1}`);
  //       }

  //       if (data.image2) {
  //         setSelectedFile2(`${process.env.REACT_APP_API_URL}/${data.image2}`);
  //       }

  //       if (data.image3) {
  //         setSelectedFile3(`${process.env.REACT_APP_API_URL}/${data.image3}`);
  //       }

  //       if (data.image4) {
  //         setSelectedFile4(`${process.env.REACT_APP_API_URL}/${data.image4}`);
  //       }
  //     }

  //  })
  // }
  const countUp = () => {
    timerRefm.current = timerRefm.current + 1;
  };
  // useEffect( ()=>{

  //   const res =  axios.get(
  //     // `${process.env.REACT_APP_API_URL}/product-service/create/`,
  //     `${process.env.REACT_APP_API_URL}/api/check_maintenance/${db_data?.sku}/`,
  //   ).then((res)=>{
  //     setLoading(false)
  //     // if("message" in res?.data){
  //     //   setAvailable(false)
  //     // }
  //     // else{
  //       setInterval(countUp, 1000);
  //       getProductImages()
  //       getSpareParts()
  //       setMentainance(res?.data)
  //       setDataPost(
  //         {
  //           ...dataPost,
  //           maintanence_id: res?.data?.id,
  //           product_id: res?.data?.product_id,
  //         }
  //       )
  //       setAvailable(true)
  //     // }
  //     console.log("res------> ",res);
  //  })

  // },[])
  useEffect(() => {
    // Assuming you have the data available locally or through an alternative method
    handleTemporaryRepairButtonClick();
    setLoading(false);

    // You can call your other functions or set your state variables here as needed
    setInterval(countUp, 1000);
    // getProductImages();
    getSpareParts();
    // Replace the next three lines with your data population logic using the available data
    // setMentainance(/* Replace with your data */);
    // // setDataPost(
    //           {
    //             ...dataPost,
    //             maintanence_id: res?.data?.id,
    //             product_id: res?.data?.product_id,
    //           }
    //         )
    setAvailable(true);
  }, []);
  const handleCardClick = (cardId, item) => {
    if (selectedCards.includes(cardId)) {
      // If card is already selected, remove it from selectedCards
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
      setSelectedItems(selectedItems.filter((item) => item?.id !== cardId));
    } else {
      // If card is not selected, add it to selectedCards
      setSelectedCards([...selectedCards, cardId]);
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleImageClick = (ref) => {
    console.log("image pciker ", ref);
    ref.current.click();
  };

  const handleFileSelect1 = (event) => {
    setSelectedFile1(URL.createObjectURL(event.target.files[0]));
    files[0] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect5 = (event) => {
    setSelectedFile5(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      picture: event.target.files[0],
    });
  };

  const handleFileSelect6 = (event) => {
    setSelectedFile6(URL.createObjectURL(event.target.files[0]));

    setDataPost({
      ...dataPost,
      video: event.target.files[0],
    });
  };

  const handleFileSelect2 = (event) => {
    setSelectedFile2(URL.createObjectURL(event.target.files[0]));
    files[1] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect3 = (event) => {
    setSelectedFile3(URL.createObjectURL(event.target.files[0]));
    files[2] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect4 = (event) => {
    setSelectedFile4(URL.createObjectURL(event.target.files[0]));
    files[3] = event.target.files[0];
    setFiles([...files]);
  };

  const handleClick = () => {
    setShowModal(true);
    // if (checked) {
    //   saveServicesSingle(index)
    // }
  };
  console.log("checkingHaDev timer check", timer);

  // const startTimerController = (index) => {
  //   const alreadyStarted = timerRef.current[index]
  //   console.log("checkingHaDev startTimerController", index, alreadyStarted)

  //   if (!alreadyStarted) {
  //     timerRef.current[index] = setInterval(() => {
  //       timers.current[index] = (timers.current[index] || 0) + 1

  //       setTimer((prev) => ({ ...prev, [index]: (prev[index] || 0) + 1 }))
  //     }, 1000)
  //   }
  // }
  // const endTimerController = (index) => {
  //   const alreadyStarted = timerRef.current[index]

  //   if (alreadyStarted) {
  //     const timerValue = timers.current[index]

  //     if (!timerValue) {
  //       timers.current[index] = 0
  //     }

  //     clearInterval(timerRef.current[index])
  //   }
  // }

  const postImgs = async () => {
    const data = new FormData();
    data.append("product", product_id);
    for (let i = 0; i < files.length; i++) {
      if (files[i] != null) {
        data.append(`image${i + 1}`, files[i]);
      }
    }
    if (files.length == 0) {
      toast.error("Value is empty!");
      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/service-image/`,
          data
        );
        if (res) {
          setSelectedFile1("");
          setSelectedFile2("");
          setSelectedFile3("");
          setSelectedFile4("");
        }
        // alert("service Images  has been saved")
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  const handleConfirm = () => {
    // Handle confirm logic here
    setShowModal(false);
  };

  const handleCancel = () => {
    // Handle cancel logic here
    setShowModal(false);
  };
  // console.log("services__services__services__services__", services)

  useEffect(() => {
    if (db_data?.vin_code) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/addwork/add-work/latest-work/${db_data?.vin_code}/`
        )
        .then((response) => {
          setDataPost((prevData) => ({
            ...prevData,
            customer_description: response.data?.work_order_description || "",
            receiver_description: response.data?.repair_description || "",
          }));
          setLatestWorkData(response.data);
        })
        .catch((error) => {
          toast.error("Error fetching work.");
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const convertImageToFile = async (imageUrl, fileName = "image.jpg") => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob(); // Fetch the image as a blob

      // Convert the blob to a File object
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error("Error converting image:", error);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/vehicle-images/${db_data?.vin_code}/`
      )
      .then((response) => {
        setSelectedFile1(response.data?.speedometer_picture || null);
        setSelectedFile2(response.data?.vin_picture || null);
        setSelectedFile3(response.data?.front_45_view_picture || null);
        setSelectedFile4(response.data?.rear_45_view_picture || null);
        // setFiles([
        //   convertImageToBinary(response.data?.speedometer_picture) || null,
        //   convertImageToBinary(response.data?.vin_picture) || null,
        //   convertImageToBinary(response.data?.front_45_view_picture) || null,
        //   convertImageToBinary(response.data?.rear_45_view_picture) || null,
        // ]);

        const imageUrls = [
          response.data?.speedometer_picture,
          response.data?.vin_picture,
          response.data?.front_45_view_picture,
          response.data?.rear_45_view_picture,
        ];

        // Map the URLs to blobs and update state
        Promise.all(imageUrls.map((url) => convertImageToFile(url)))
          .then((blobs) => {
            setFiles(blobs); // Set files to blobs
          })
          .catch((error) => {
            console.error("Error fetching or converting images:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching temp images data:", error);
      });
  }, []);

  useEffect(() => {
    // First API call
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/${db_data?.vin_code}/`
      )
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          console.error("Error fetching vehicle maintenance history:");
        } else {
          setDataPost((prevData) => ({
            ...prevData,
            mileage: response?.data[response.data.length - 1]?.current_mileage,
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching vehicle maintenance history:", error);
      });
  }, [db_data?.vin_code, db_data?.Factory_name]);

  return (
    <>
      {
        // !loading?(
        <>
          <div className="flex">
            <Sidebar
              openSidebar={openSidebar}
              setOpenSidebar={setOpenSidebar}
              onItemClick={savetempMentainence}
            />

            <Header setOpenSidebar={setOpenSidebar} />

            <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
              {/* ADD NEW SERVICE */}
              <div className="px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16">
                <div className="flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14">
                  {/* LEFT */}
                  <div className="w-full mb-10 md:mb-0 md:w-1/2 md:pt-3 xl:pt-12">
                    {/* IMAGE */}
                    <div>
                      <img className="mx-auto" src={image_url} alt="bike-img" />
                    </div>
                  </div>

                  {/* RIGHT */}
                  <div className="w-full md:w-1/2 flex flex-col self-center">
                    <div className="md:pl-5 xl:pl-[30px]">
                      {/* BIKE NAME */}
                      <div className="mb-3 md:mb-1 xl:mb-[86px]">
                        <h3 className="bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold">
                          {db_data?.model_name}, {db_data?.year},{" "}
                          {db_data?.color}
                        </h3>
                      </div>

                      {/* SERVICE-KM */}
                      <div className="mb-3 md:mb-[30px] xl:mb-[83px]">
                        <h1 className=" text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold">
                          Repairing
                        </h1>
                      </div>

                      {/* SERVICE DESCRIPTION */}
                      <div>
                        <h4 className="text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4">
                          {t("Mileage_of_Repairing")}{" "}
                          <span>
                            <input
                              value={dataPost?.mileage}
                              //  value={responseData?.mileage || ''}

                              onChange={(event) => {
                                onChangeValue(event, "mileage");
                              }}
                              type="number"
                              class="rounded-lg px-3 py-2 bg-gray-900 w-24 h-8 border border-gray-400"
                            />
                          </span>{" "}
                          km
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* VEHICLE PHOTOS */}
                {/* VEHICLE PHOTOS */}
                <div className="mb-[67px] md:mb-[41px] xl:mb-[90px]">
                  {/* TITLE */}
                  <div className="mb-6 md:mb-14 xl:mb-[43px]">
                    <h3 className="text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5">
                      {t("Vehicle_photos")}
                    </h3>
                    <p className=" text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100">
                      {t("Vehicle_photos_desc")}
                    </p>
                  </div>

                  <div className="grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]">
                    {[
                      {
                        file: selectedFile1,
                        placeholder: "Addspeedometer",
                        onClick: () => handleImageClick(fileInputRef1),
                        fileInputRef: fileInputRef1,
                        onChange: handleFileSelect1,
                      },
                      {
                        file: selectedFile2,
                        placeholder: "addvincode",
                        onClick: () => handleImageClick(fileInputRef2),
                        fileInputRef: fileInputRef2,
                        onChange: handleFileSelect2,
                      },
                      {
                        file: selectedFile3,
                        placeholder: "add45front",
                        onClick: () => handleImageClick(fileInputRef3),
                        fileInputRef: fileInputRef3,
                        onChange: handleFileSelect3,
                      },
                      {
                        file: selectedFile4,
                        placeholder: "add45back",
                        onClick: () => handleImageClick(fileInputRef4),
                        fileInputRef: fileInputRef4,
                        onChange: handleFileSelect4,
                      },
                    ].map((image, index) => (
                      <div key={index}>
                        <div
                          className="w-full h-[156px] md:h-[196px] xl:h-[168px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
                          style={{
                            backgroundImage: image.file
                              ? `url(${image.file})`
                              : "none",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          onClick={image.onClick}
                        >
                          {!image.file && (
                            <div className="flex flex-col justify-center items-center cursor-pointer text-center">
                              <img src={placeholder_img} alt="add-img" />
                              <div
                                className="text-f_18_l_28 mt-3.5 break-all hyphens-auto"
                                style={{ wordBreak: "break-word" }}
                              >
                                {t(image.placeholder)}
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          ref={image.fileInputRef}
                          style={{ visibility: false, display: "none" }}
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={image.onChange}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Customer description of errors: */}
                <div className="mb-7">
                  <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
                    {t("Customer_description_of_errors")}
                  </h3>

                  <textarea
                    value={dataPost?.customer_description}
                    onChange={(event) => {
                      onChangeValue(event, "customer_description");
                    }}
                    className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full"
                  />
                </div>
                {/* The reciever of the work description: */}
                <div className="mb-7">
                  <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
                    {t("The_receiver_oftheworks_description")}
                  </h3>

                  <textarea
                    onChange={(event) => {
                      onChangeValue(event, "receiver_description");
                    }}
                    value={dataPost?.receiver_description}
                    className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full"
                  />
                </div>
                {/*  Feedback from the mechanic */}
                <div className="mb-7">
                  <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
                    {t("Feedback_from_the_mechanic")}
                  </h3>

                  <textarea
                    value={dataPost?.feedback}
                    onChange={(event) => {
                      onChangeValue(event, "feedback");
                    }}
                    className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full"
                  />
                </div>
                {/* VEHICLE PHOTOS */}
                <div className="mb-[67px] md:mb-[41px] xl:mb-[90px]">
                  {/* TITLE */}
                  <div className="mb-6 md:mb-14 xl:mb-[43px]">
                    <h3 className="text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5">
                      {t("Upload_photos_and_videos")}
                    </h3>
                    {/* <p className=" text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100">
                        Photo of the speedometer (numbers must be legible), VIN code
                        (numbers must be legible), 45° front view ja 45° rear view
                      </p> */}
                  </div>

                  <div className="grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]">
                    {/* <div>
                      <div
                        className="w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
                        style={{
                          backgroundImage: selectedFile5
                            ? `url(${selectedFile5})`
                            : "none",
                        }}
                        onClick={(e) => handleImageClick(fileInputRef5)}
                      >
                        {!selectedFile5 && (
                          <div className="flex flex-col justify-center items-center cursor-pointer text-center">
                            <img src={placeholder_img} alt="add-img" />
                            <div
                              className="text-f_18_l_28 mt-3.5 break-all hyphens-auto"
                              style={{ wordBreak: "break-word" }}
                            >
                              Add Photo
                            </div>
                          </div>
                        )}
                      </div>

                      <input
                        type="file"
                        ref={fileInputRef5}
                        style={{ visibility: false, display: "none" }}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleFileSelect5}
                      />
                    </div>

                    <div>
                      <div
                        className="w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
                        style={{
                          backgroundImage: selectedFile6
                            ? `url(${selectedFile6})`
                            : "none",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        onClick={(e) => handleImageClick(fileInputRef6)}
                      >
                        {!selectedFile6 ? (
                          <div className="flex flex-col justify-center items-center cursor-pointer text-center">
                            <img src={placeholder_img} alt="add-img" />
                            <div
                              className="text-f_18_l_28 mt-3.5 break-all hyphens-auto"
                              style={{ wordBreak: "break-word" }}
                            >
                              Add Video
                            </div>
                          </div>
                        ) : (
                          <>
                            <video
                              src={selectedFile6}
                              style={{
                                height: "100%",
                                objectFit: "fill",
                              }}
                            ></video>
                          </>
                        )}
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef6}
                        style={{ visibility: false, display: "none" }}
                        accept="video/mp4, video/quicktime"
                        onChange={handleFileSelect6}
                      />
                    </div> */}
                    <MultiImageUpload
                      setData={setDataPost}
                      filesInputRef={filesInputRef}
                      images={images}
                      setImages={setImages}
                    />
                    <MultiVideoUpload
                      setData={setDataPost}
                      videosInputRef={videosInputRef}
                      videos={videos}
                      setVideos={setVideos}
                    />
                  </div>
                </div>
                {/* SPARE PARTS FOR SERVICE */}
                <div className="mb-10 md:mb-8 xl:mb-[51px]">
                  {/* TITLE */}
                  {/* <div className="mb-6 md:mb-[26px]">
                    <h3 className="text-f_20_l_25 xl:text-f_22_l_28 text-white-100 font-supremeBold">
                      {t("Replaced_parts")}
                    </h3>
                  </div> */}

                  {/* ITEMS LIST */}

                  <div className="space-y-4 md:space-y-[27px] xl:space-y-8">
                    {/* ITEM */}

                    {selectedItems.map((item) => (
                      <>
                        <div className="bg-black-500 border-[0.5px] border-white-100 rounded-[10px] flex items-center px-4 md:pl-[34px] xl:pl-14 pt-3.5 xl:pt-[17px] pb-5">
                          {/* LEFT */}
                          <div className="w-[45%] md:w-[43.666667%] flex flex-col space-y-2 xl:space-y-4 uppercase">
                            <span className="text-blue text-f_16_l_20 xl:text-f_18_l_23">
                              SKU
                            </span>
                            <span className="text-f_14_l_18 md:text-f_13_l_16 xl:text-f_18_l_23">
                              {item?.product}
                            </span>
                          </div>

                          {/* RIGHT */}
                          <div className="flex flex-col space-y-2 xl:space-y-4 uppercase">
                            <span className="text-f_16_l_20 xl:text-f_18_l_23 text-blue">
                              NAME
                            </span>
                            <span className="text-f_14_l_18 md:text-f_13_l_16 xl:text-f_18_l_23">
                              {item?.part_name}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}

                    {/* <Sparepartmodal/> */}
                    {/* <button
                      className="p-4  rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
                      onClick={handleClick}
                      style={{ backgroundColor: "#181818" }}
                    >
                      <span style={{ fontSize: "24px", color: "#009bb4" }}>
                        +
                      </span>
                    </button> */}

                    <Modal
                      isOpen={showModal}
                      onRequestClose={() => setShowModal(false)}
                      className="fixed inset-0 flex items-center justify-center z-50"
                      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                    >
                      <div className="bg-black-500 rounded-lg p-6">
                        <h1 className="text-xl font-bold mb-4">
                          Replaced parts
                        </h1>
                        <p className="bg-gradient-text mb-6">
                          you have to select replace parts
                        </p>
                        <div className="grid grid-cols-2 gap-4">
                          {spareParts.map((item, index) => (
                            <>
                              <div
                                className={`card bg-white rounded-lg p-4 ${
                                  selectedCards.includes(item?.id)
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() => handleCardClick(item?.id, item)}
                              >
                                <h2 className="text-lg font-semibold">
                                  {item?.part_name}
                                </h2>
                                <p>{item?.product}</p>
                              </div>
                            </>
                          ))}

                          {/* Add more cards as needed */}
                        </div>
                        <div className="flex justify-end mt-6">
                          <button
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 mr-2"
                            onClick={handleConfirm}
                          >
                            Confirm
                          </button>
                          <button
                            className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <style>{`
              .card {
                /* Add your regular card styles here */
                border: 2px solid #ffffff; /* Set the default border color */
              }
              
              .card.selected {
                border-color: #009bb4; /* Set the border color for selected cards */
              }
            `}</style>
                  </div>
                </div>
                {/* SAVE SERVICE DATA BUTTON */}
                <button
                  onClick={() => {
                    saveMentainence();
                  }}
                  className="p-4 bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
                >
                  {t("Save_repairing_Data")}
                </button>
                <br />

                {responseData && responseData.length !== 0 ? null : null}
              </div>
            </main>
          </div>
        </>
        // ):(<>
        //   <div className="flex">
        //       <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        //       <Header setOpenSidebar={setOpenSidebar} />

        //       <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">

        //         </main>
        //         </div>
        // </>)
      }
    </>
  );
}

export default Maintainence;
