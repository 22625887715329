import React, { useState, useRef, useEffect } from "react";
import placeholder_img from "../../images/camera_img.svg";

const MultiVideoUpload = ({ setData, videosInputRef, videos, setVideos }) => {
  const handleVideoUpload = (event) => {
    const files = event.target.files;
    if (files) {
      const newVideos = Array.from(files).map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      // Filter out duplicate videos based on file name (name)
      const filteredNewVideos = newVideos.filter(
        (newVideo) => !videos.some((video) => video.name === newVideo.name) // Compare by file name
      );

      // Combine existing videos and new videos, then remove duplicates
      setVideos((prevVideos) => {
        const allVideos = [...prevVideos, ...filteredNewVideos];
        const uniqueVideos = allVideos.filter(
          (video, index, self) =>
            index === self.findIndex((t) => t.name === video.name) // Remove duplicates based on file name
        );

        // Update `setData` to include the non-duplicate videos
        setData((prevData) => {
          const allVideoData = [
            ...(prevData.video || []), // Add existing videos from parent data
            ...filteredNewVideos, // Add new non-duplicate videos
          ];

          const uniqueVideoData = allVideoData.filter(
            (video, index, self) =>
              index === self.findIndex((t) => t.name === video.name) // Remove duplicates based on file name
          );

          return {
            ...prevData,
            video: uniqueVideoData, // Set the unique video list
          };
        });

        return uniqueVideos; // Set unique videos in the component state
      });
    }
  };

  const removeVideo = (index) => {
    setVideos((prevVideos) => {
      const updatedVideos = prevVideos.filter((_, i) => i !== index);

      // Update `setData` with the modified list
      setData((prevData) => ({
        ...prevData,
        video: updatedVideos, // Update the state with the modified videos list
      }));

      return updatedVideos;
    });
  };

  const handleAddMoreClick = () => {
    if (videosInputRef.current) {
      videosInputRef.current.click();
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto">
      <input
        type="file"
        ref={videosInputRef}
        onChange={handleVideoUpload}
        multiple
        accept="video/mp4, video/quicktime"
        className="hidden"
      />
      <div
        className="w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
        onClick={handleAddMoreClick}
      >
        <div className="flex flex-col justify-center items-center cursor-pointer text-center">
          <img src={placeholder_img} alt="add-video" />
          <div
            className="text-f_18_l_28 mt-3.5 break-all hyphens-auto"
            style={{ wordBreak: "break-word" }}
          >
            Add Video
          </div>
        </div>
      </div>

      {videos.length > 0 && (
        <>
          <div className="text-sm text-gray-500 mb-2">
            {videos.length} video{videos.length !== 1 ? "s" : ""} selected
          </div>
          <div className="border border-gray-200 rounded-lg p-4 mb-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-h-96 overflow-y-auto">
              {videos.map((video, index) => (
                <div key={index} className="relative group min-w-[120px]">
                  <video
                    src={video.preview}
                    alt={`Uploaded video ${index + 1}`}
                    className="rounded-md object-cover w-full h-24"
                    controls
                  />
                  <button
                    onClick={() => removeVideo(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                    aria-label="Remove video"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-x text-blue"
                    >
                      <path d="M18 6 6 18" />
                      <path d="m6 6 12 12" />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiVideoUpload;
