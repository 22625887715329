import React from "react";
import placeholder_img from "../../images/camera_img.svg";

const MultiImageUpload = ({ setData, filesInputRef, images, setImages }) => {
  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files).map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file), // Preview URL as a unique identifier
        })
      );

      // Filter out images that are already in the images list based on file name
      const filteredNewImages = newImages.filter(
        (newImage) => !images.some((image) => image.name === newImage.name) // Compare by file name
      );

      // Merge existing pictures from prevData and new images, then filter duplicates by name
      setImages((prevImages) => {
        const allImages = [...prevImages, ...filteredNewImages];
        const uniqueImages = allImages.filter(
          (image, index, self) =>
            index === self.findIndex((t) => t.name === image.name) // Remove duplicates based on file name
        );

        // Update `setData` to include the non-duplicate images
        setData((prevData) => {
          const allPictureImages = [
            ...(prevData.picture || []),
            ...filteredNewImages,
          ];
          const uniquePictureImages = allPictureImages.filter(
            (image, index, self) =>
              index === self.findIndex((t) => t.name === image.name) // Remove duplicates based on file name
          );

          return {
            ...prevData,
            picture: uniquePictureImages, // Set the unique images without duplicates
          };
        });

        return uniqueImages; // Set unique images in the component state
      });
    }
  };

  const removeImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      setData((prevData) => ({
        ...prevData,
        picture: updatedImages, // Update the state with the modified images list
      }));
      return updatedImages;
    });
  };

  const handleAddMoreClick = () => {
    if (filesInputRef.current) {
      filesInputRef.current.click();
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto">
      <input
        type="file"
        ref={filesInputRef}
        onChange={handleImageUpload}
        multiple
        accept="image/png, image/jpeg, image/jpg"
        className="hidden"
      />
      <div
        className="w-full h-[156px] md:h-[210px] lg:h-[305px] xl:h-[280px] bg-contain bg-no-repeat bg-center flex justify-center items-center bg-black-500 rounded-[10px]"
        onClick={handleAddMoreClick}
      >
        <div className="flex flex-col justify-center items-center cursor-pointer text-center">
          <img src={placeholder_img} alt="add-img" />
          <div
            className="text-f_18_l_28 mt-3.5 break-all hyphens-auto"
            style={{ wordBreak: "break-word" }}
          >
            Add Photo
          </div>
        </div>
      </div>

      {images.length > 0 && (
        <>
          <div className="text-sm text-gray-500 mb-2">
            {images.length} image{images.length !== 1 ? "s" : ""} selected
          </div>
          <div className="border border-gray-200 rounded-lg p-4 mb-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-h-96 overflow-y-auto">
              {images.map((image, index) => (
                <div key={index} className="relative group min-w-[120px]">
                  <img
                    src={image.preview}
                    alt={`Uploaded image ${index + 1}`}
                    className="rounded-md object-cover w-full h-24"
                  />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                    aria-label="Remove image"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-x text-blue"
                    >
                      <path d="M18 6 6 18" />
                      <path d="m6 6 12 12" />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiImageUpload;
