import React, { useEffect, useState } from "react";
import Sidebar from "../components/Common/Sidebar";
import Header from "../components/Common/Header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { vinnActions } from "../state/actions";

function Dashboard({ vinnnoInRequest }) {
  const { t } = useTranslation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  const [worksData, setWorksData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const hideWelcome = () => {
    document.getElementById("welcome-div").style.display = "none";
    localStorage.setItem("hide_welcome", "true");
  };
  const shouldHideWelcome = localStorage.getItem("hide_welcome") === "true";

  async function logout_vin_and_login_function(item) {
    const userEmail = localStorage.getItem("profile_email");
    const vinnData = JSON.parse(localStorage.getItem("vinnData"));

    if (vinnData && vinnData.data && vinnData.data.vin_code) {
      const vinCode = vinnData.data.vin_code;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/vehicles/logout_vehicle/${userEmail}/?Vehicle_servicing=${vinCode}`
      );

      if (response.ok) {
        const number = { numb: item?.vin_code };
        await vinnnoInRequest({ number: number, navigate: navigate });
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } else {
      const number = { numb: item?.vin_code };
      await vinnnoInRequest({ number: number, navigate: navigate });
    }
  }

  const filteredWorks = worksData.filter((work) =>
    work.vin_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits (e.g., '03' instead of '3')
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0'); // Ensure hour is 2 digits
    const minute = String(date.getMinutes()).padStart(2, '0'); // Ensure minute is 2 digits
    
    return `${day}/${month}/${year} at ${hour}:${minute}`;
  };

  const renderWorkItems = (status) => {
    const getStatusIcon = (complete) => {
      const iconProps = {
        xmlns: "http://www.w3.org/2000/svg",
        className: "h-6 w-6 mr-2 text-green-400",
        fill: "none",
        viewBox: "0 0 24 24",
        stroke: complete ? "currentColor" : "red",
      };
      const pathData = complete
        ? "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        : "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z";

      return (
        <svg {...iconProps}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d={pathData}
          />
        </svg>
      );
    };

    return filteredWorks
      .filter((item) => item.status === status)
      .map((item) => (
        <div
          key={item.work_id}
          className="p-4 mb-2 shadow-lg cursor-pointer border border-gray-600 rounded-lg bg-black-400"
          onClick={() => status !== 3 && logout_vin_and_login_function(item)}
        >
          <span className="font-semibold text-f_12_l_15 md:text-f_14_l_22">
            {item.vin_code}
          </span>
          <p className="text-sm text-gray-600">
            Created: {formatDate(item.created_at)}
          </p>
          <div className="mt-2 flex flex-wrap gap-6">
            {item.is_maintenance && (
              <div className="flex items-center">
                <span className="text-sm">Maintenance</span>
                {getStatusIcon(item.is_maintenance_complete)}
              </div>
            )}
            {item.is_repair && (
              <div className="flex items-center">
                <span className="text-sm">Repair</span>
                {getStatusIcon(item.is_repair_complete)}
              </div>
            )}
            {item.is_warranty && (
              <div className="flex items-center">
                <span className="text-sm">Warranty</span>
                {getStatusIcon(item.is_warranty_complete)}
              </div>
            )}
          </div>
        </div>
      ));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/addwork/add-work`)
      .then((response) => {
        setWorksData(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching works.");
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div className="flex">
        <Sidebar
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          activeMe="dashboard"
        />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className="min-h-[100vh] md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
          {/* DASHBOARD */}
          <div className="pt-10 pb-[10.4375rem] py-[3.0625rem] xl:py-[3.8125rem] px-[26px] md:px-8 xl:px-[3.4375rem]">
            {!shouldHideWelcome && (
              <div
                id="welcome-div"
                className="bg-black-200 pt-[30px] md:pt-9 pl-5 md:pl-10 xl:pl-[3.4375rem] pr-3 md:pr-8 pb-[5.3125rem] xl:pb-[6.375rem] rounded-[25px]"
              >
                {/* TITLE */}
                <div className="mb-4 md:mb-[38px] flex justify-between items-center">
                  <h1 className="text-f_45_l_57 font-supremeMedium">
                    Welcome {localStorage.getItem("profile")}
                  </h1>
                  <button
                    onClick={hideWelcome}
                    className="text-red-500 text-2xl font-bold hover:text-red-700"
                  >
                    &times;
                  </button>
                </div>

                {/* Learn How to use this System */}
                <div className="mb-[18px] md:mb-8 xl:mb-9">
                  <h3 className="bg-gradient-text text-f_25_l_32 mb-3 md:mb-2.5">
                    {/* Learn How to use this System */}
                    {t("dasboard_learn")}
                  </h3>
                  <p className="text-f_18_l_28">{t("DESC_USE")}</p>
                </div>

                {/* STEPS */}
                <div className="space-y-3 md:space-y-[5px] xl:space-y-[18px] mb-4 md:mb-6 xl:mb-11">
                  {/* STEP 1 */}
                  <div>
                    <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                      {t("Step_1")}
                    </h3>
                    <p className="text-f_18_l_28">{t("Step_1_desc")}</p>
                  </div>

                  {/* STEP 2 */}
                  <div>
                    <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                      {t("Step_2")}
                    </h3>
                    <p className="text-f_18_l_28">{t("Step_2_desc")}</p>
                  </div>

                  {/* STEP 3 */}
                  <div>
                    <h3 className="bg-gradient-text text-f_22_l_28 mb-[5px]">
                      {t("Step_3")}
                    </h3>
                    <p className="text-f_18_l_28">{t("Step_3_desc")}</p>
                  </div>
                </div>

                {/* System tutorial videos */}
              </div>
            )}

            <div className="w-full flex flex-row xsm:flex-col justify-center gap-12 xsm:gap-4 pt-16">
              <div className="container mx-auto p-4">
                <div className="flex justify-between items-center mb-6 gap-2">
                  <div className="relative flex-grow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                    <input
                      type="search"
                      placeholder="Search by VIN..."
                      className="pl-10 pr-4 py-2 w-full border border-white-100 rounded-[10px] bg-transparent"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <button
                    className="border border-white-100 rounded-[10px] bg-transparent font-bold py-2 px-2 rounded inline-flex items-center"
                    onClick={() => navigate("/service/Addwork")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Add New Work
                  </button>
                </div>

                <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-2 md:gap-6">
                  <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-2 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Pending
                      <span className="ml-2 text-sm text-red border border-red px-2 py-1 rounded-full">
                        {
                          filteredWorks.filter((item) => item.status === 1)
                            .length
                        }
                      </span>
                    </h2>
                    {renderWorkItems(1)}
                  </div>

                  <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6 mr-2 text-gray-500"
                      >
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                      </svg>
                      Workshop
                      <span className="ml-2 text-sm text-[#FFD009] border border-[#FFD009] px-2 py-1 rounded-full">
                        {
                          filteredWorks.filter((item) => item.status === 2)
                            .length
                        }
                      </span>
                    </h2>
                    {renderWorkItems(2)}
                  </div>

                  <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-2 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Completed
                      <span className="ml-2 text-sm text-sm text-blue border border-blue px-2 py-1 rounded-full">
                        {
                          filteredWorks.filter((item) => item.status === 3)
                            .length
                        }
                      </span>
                    </h2>
                    {renderWorkItems(3)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    number: state.number,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vinnnoInRequest: (number) => dispatch(vinnActions.vinnInRequest(number)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "Dashboard" })(Dashboard));
